import React from 'react';
import styled from "styled-components"
import { Grid } from '@material-ui/core';

const imageRow = (props) => {
  const Image = props.normal ? styled.img`
  width: 300px;
  height: 300px;
  border-radius: 4px;`
  : styled.img`
  height: 333px;
  object-fit: cover;
  width: 333px;
  border: 5px solid #F1BA43;`

  return (
    <Grid container justify="center">
      {props.imageOne && <Grid item md={4} lg={3}>
        <Image src={require(`../images/${props.imageOne}.jpg`).default}/>
      </Grid>}
      {props.imageTwo && <Grid item md={4} lg={3}>
        <Image src={require(`../images/${props.imageTwo}.jpg`).default}/>
      </Grid>}
      {props.imageThree && <Grid item md={4} lg={3}>
        <Image src={require(`../images/${props.imageThree}.jpg`).default}/>
      </Grid>}
      {props.imageFour && <Grid item md={4} lg={3}>
        <Image src={require(`../images/${props.imageFour}.jpg`).default}/>
      </Grid>}
    </Grid>
  );
};

export default imageRow;
