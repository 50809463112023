import React from 'react';
import ImageRow from '../components/imageRow'
import styled from 'styled-components'
import Header from '../components/header'
import { Grid, makeStyles, GridList, GridListTile, isWidthUp, withWidth } from '@material-ui/core';
import Footer from '../components/footer';
import { images } from '../components/images';
import { Link } from 'gatsby';

const HeroImage = styled.img`
  height: 600px;
  object-fit: cover;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @include media-breakpoint-down(sm) {
    height: 400px;

  }
`

const useStyles = makeStyles((theme) => ({
  home: {
    padding: '20px 60px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px'
    }
  }
}));

const H2 = styled.h2`
  font-size: 18px;
`

const P = styled.p`
  font-size: 18px;
`

const StyledLink = styled(Link)`
  color: black;
  background-color: #F1BA43;
  padding: 10px;
  border-radius: 10px;
`

const Home = (props) => {
  const getGridListCols = (width) => {
    if (isWidthUp('lg', width)) {
      return 4;
    }
    if (isWidthUp('md', width)) {
      return 3;
    }
    if (isWidthUp('sm', width)) {
      return 2;
    }
  }
  const classes = useStyles();

  return (
    <div>
      <Header title="Home" />
      <Grid container>
        {/* <Grid container item xs={12} justify="center">
          ANNOUCEMENT: WE ARE AVAILABLE ON ETSY!! Click here to check out our list of treats available for shipping!
        </Grid> */}
        <Grid container item xs={12}>
          <GridList cellHeight={130} cols={getGridListCols(props.width)}>
            {images.map((tile) => (
              tile.image &&
                <GridListTile key={tile.itemName} cols={tile.cols} rows={tile.rows}>
                  <img src={require(`../images/${tile.image}.jpg`).default} alt={tile.itemName} />
                </GridListTile>
            ))}
          </GridList>
        </Grid>
        <Grid className={classes.home} container item xs={12}>
          <Grid item xs={12}>
            <H2>Freshly baked treats, made to order</H2>
          </Grid>
          <Grid item xs={12} >
            <StyledLink to="/treats">View our Treats Gallery</StyledLink>
            <P>Every item is made from scratch and guaranteed fresh for all our customers.</P>
          </Grid>
          <ImageRow
            normal={true}
            imageOne="cakes/custom25"
            imageTwo="cakes/birthday5"
            imageThree="cakes/babyshower3"
            imageFour="cakes/birthday26"
          />
          <Grid item xs={12}>
            <H2>Custom Cakes & Treats for any occassion</H2>
          </Grid>
          <Grid item xs={12}>
            <StyledLink to="/cakes">View our Cakes Gallery</StyledLink>
            <P>Our goodies can be ordered for any occasion. Birthdays, Graduations, Diwali, Christmas, anything you can think of, we are here for you!</P>
          </Grid>
          <ImageRow
            normal={true}
            imageOne="cookies/babyshower3"
            imageTwo="cookies/baseball3"
            imageThree="cookies/birthdaytheme4"
            imageFour="cookies/custom2"
          />
          <Grid item xs={12}>
            <H2>Located in Queens, New York</H2>
          </Grid>
          <Grid item xs={12}>
            <P>All cakes must be picked up! Cookies can be shipped.</P>
            <StyledLink to="/faq">Learn more about our bakery</StyledLink>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <HeroImage src={require('../images/other/cupcakes18.jpg').default} />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default  withWidth()(Home);
