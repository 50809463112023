export const images = [
  {
    itemName: "western 6",
    cols: 2,
    rows: 3.5,
    image: "cookies/western6" ,
  },
  {
    itemName: "cupcakes14",
    cols: 1,
    rows: 3.5,
    image: "other/cupcakes14" ,
  },
  {
    itemName: "vday",
    cols: 1,
    rows: 3.5,
    image: "cookies/vday10" ,
  },
  {
    itemName: "cupcakes24",
    cols: 1,
    rows: 3.5,
    image: "other/cupcakes24" ,
  },
  {
    itemName: "babyshower1",
    cols: 1,
    rows: 3.5,
    image: "cakes/babyshower1" ,
  },
  {
    itemName: "vday11",
    cols: .75,
    rows: 3.5,
    image: "cookies/vday11" ,
  },
  {
    itemName: "birthday21",
    cols: 1.25,
    rows: 3.5,
    image: "cakes/birthday20" ,
  }
]
